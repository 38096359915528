.headline-form {
  &__result {
    &-items {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -8px -8px -8px;
    }

    &-item {
      display: flex;
      margin: 0 8px 8px 8px;

      > {
        .form-control {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right-width: 0;
          height: auto;
        }

        .btn-group {
          > .btn:first-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 1;
    }
  }
}

*[dir='rtl'] {
  .headline-form {
    &__result {
      &-item {
  
        > {
          .form-control {
            border-top-right-radius: .25rem;
            border-bottom-right-radius: .25rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-right-width: 1px;
            border-left-width: 0;
          }
  
          .btn-group {
            > .btn:first-child {
              border-top-left-radius: .25rem;
              border-bottom-left-radius: .25rem;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }
    }
  }
}
