.add-emails-form {
  display: flex;

  &__email-input {
    max-width: 600px;
  }
  
  &__right-inputs {
    padding: 1rem;
    border-left: 1px solid #dee2e6;
  }
  
  &__left-inputs {
    width: 600px;
    flex: 0 0 600px;
    padding: 1rem;
  }
}
