.email-list {
  &__card {
    margin-bottom: 20px;

    .card-body,
    .card-header {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      text-align: initial;
    }

    .card-text {
      width: 100%;
    }
  }

  &__image {
    padding-right: 20px;
    max-width: 170px;
    height: 150px;
    width: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
