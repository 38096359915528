.add-emails-form {
  &__main-row {
    display: flex;

    > div {
      &:first-child {
        width: 100%;
        padding-left: 14px;
      }
    }

    textarea {
      height: 90px;
    }
  }

  &__email-list {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: relative;
    padding-right: 40px;

    &:hover {
      cursor: grab;
    }

    &-dnd {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0;
      top: 0;
      bottom: 0;
      width: 40px;
      opacity: .3;
    }
  
    .btn-group {
      margin-right: 0;
    }
  }

  &__image {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-bottom: 1rem;
    max-height: 230px;

    .custom-file-label {
      &:after {
        width: 100%;
        border-right: 0;
        text-align: center;
      }
    }

    &-container {
      border: 1px solid #dee2e6;
      border-radius: .25rem;
      background-color: #e9ecef;
      position: relative;
      margin-bottom: 6px;
      flex: 1;

      &--loading {
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255, 255, 255, .8);
        }
      }

      .spinner-border {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }

    &-group {
      max-width: 150px;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
