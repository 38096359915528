.head {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;

  .btn-group {
    margin-right: 0;
  }
}
