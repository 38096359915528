.presentation-modal {
  > .modal-dialog {
    max-height: 100vh;
    margin-top: 14px;
    margin-bottom: 14px;
    max-width: 836px;
    width: 100%;

    .modal-body {
      height: 100%;

      > form {
        height: 100%;

        .add-presentations-form {
          height: 100%;
        }
      }

      .add-presentations-form__right-inputs {
        max-height: 100%;
        overflow: auto;
      }

      .add-presentations-form__presentation-input {
        height: 100%;

        .tox-tinymce {
          min-height: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
