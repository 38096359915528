.list {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .btn-group {
      margin-right: 0;
    }
  }
}
