.app-container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.form {
  &__delete {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    > div:not(.btn-group) {
      width: 100%;
    }

    .btn-group {
      margin-left: 10px;
    }
  }
}

*[dir='rtl'] {
  .form {
    &__delete {
      .btn-group {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}

*[dir='rtl'] {
  .btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .btn-group>.btn:not(:last-child):not(:first-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

form,
.form-group {
  text-align: initial;
}

.modal-body {
  text-align: initial;
}

.modal-title {
  width: 100%;
  text-align: initial;
}

.modal-footer {
  justify-content: initial;
}

.btn-link {
  color: black;
  text-decoration: underline;
}

.rtl-span {
  direction: ltr;
  display: inline-block;
}

*[dir='rtl'] {
  .custom-file-label::after {
    right: auto;
    left: 0;
    border-left: initial;
    border-radius: .25rem 0 0 .25rem;
    border-right: inherit;
  }
}

.question-modal-backdrop,
.inner-backdrop {
  z-index: 1050;
}
