.presentation-preview-modal {
  &__preview {
    max-width: 600px;
    width: 100%;
    margin: auto;
  }

  .modal-dialog {
    height: 100vh;
    width: auto;
    max-width: 133vh;
  }

  .modal-body {
    height: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner-border {
      position: absolute;
    }

    iframe {
      height: 100%;
      width: 100%;
    }
  }
}
