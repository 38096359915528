.email-modal {
  > .modal-dialog {
    max-height: 100vh;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 1140px;
    width: 100%;

    .modal-content {
      max-height: 100vh;
    }

    .modal-body {
      height: 100%;

      > form {
        height: 100%;

        .add-emails-form {
          height: 100%;
        }
      }

      .add-emails-form__right-inputs {
        max-height: 100%;
        overflow: auto;
      }

      .add-emails-form__email-input {
        height: 100%;

        .tox-tinymce {
          min-height: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
