.email-preview-modal {
  &__preview {
    max-width: 600px;
    width: 100%;
    margin: auto;
  }

  .modal-dialog {
    width: 100%;
    max-width: calc(600px + 2rem);
  }
}
