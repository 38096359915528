.add-vsl-slide-form {
  display: flex;

  &__email-input {
    max-width: none;

    .tox-edit-area {
      width: 100%;
      height: 0;
      padding-bottom: 75%;
    }
  }
  
  &__right-inputs {
    padding: 1rem;
    border-left: 1px solid #dee2e6;
  }
  
  &__left-inputs {
    width: 834px;
    flex: 0 0 834px;
    padding: 1rem;
  }

  &--vsl {
    .add-vsl-slide-form {
      &__email-input {
        max-width: 834px;
        width: 100%;
      }
    }

    display: block;
  }
}
